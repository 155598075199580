<template>
  <div>
    <div class="header">
      <div class="inner-header flex py-md-0  px-10">
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="6">
            <v-card
              :width="$vuetify.breakpoint.mdAndUp ? '500' : ''"
              :loading="$store.state.loading"
              class="mt-10 "
            >
              <v-card-title class="d-flex flex-column">
                <span class="my-5"> تسجيل الدخول </span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  @submit.prevent="submit()"
                  lazy-validation
                >
                  <v-text-field
                    v-model="form.identifier"
                    :rules="[$rules.required()]"
                    outlined
                    label="البريد الالكتروني او اسم المستخدم"
                    required
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="form.password"
                    :rules="[$rules.required()]"
                    label="كلمة المرور"
                    type="password"
                    outlined
                    required
                  >
                  </v-text-field>
                  <h3 class="error--text ml-2 mb-5" v-if="error">
                    غير مخول بالدخول للنظام
                  </h3>
                  <v-btn block color="primary" :disabled="!valid" type="submit">
                    تسجيل الدخول
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-img :width="$vuetify.breakpoint.mdAndUp ? '' : '400'" contain src="../../assets/logo-light.png" />
            <div class="border"></div>
            <h3 class="mt-2" style="text-align: right">
              نظام تصميم المنيو وادارة الطلبات اونلاين
            </h3>
          </v-col>
        </v-row>
      </div>

      <!--Waves Container-->
      <div class="mt-10">
        <svg
          class="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shape-rendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g class="parallax">
            <use
              xlink:href="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255,255,255,0.7"
            />
            <use
              xlink:href="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.5)"
            />
            <use
              xlink:href="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.3)"
            />
            <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </div>
      <!--Waves end-->
    </div>
    <!--Header ends-->

    <!--Content starts-->
    <div class="content flex">
      <p>
        Copyright © {{ new Date().getFullYear() }} -
        <a href="http://ft.iq" target="_blank" rel="noopener noreferrer"
          >FutureTech</a
        >
      </p>
    </div>
    <!--Content ends-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        identifier: "",
        password: "",
      },
      valid: false,
      error: false,
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$store.commit("setLoading", true);
        this.$http
          .post("/auth/local", {
            identifier: this.form.identifier,
            password: this.form.password,
          })
          .then(async (res) => {
            // console.log(res);
            if (res.status == 200) {
              this.error = false;
              this.$store.commit("setUserData", res.data);
              let resturant = await this.$http.get("/info", {
                headers: {
                  Authorization: `Bearer ${res.data.jwt}`,
                },
              });
              this.$store.commit("setResturant", resturant.data.data[0]);
              this.$router.push({ name: "settings" });
            } else {
              this.error = true;
              this.$store.commit("setLoading", false);
            }
          })
          .catch((err) => {
            console.log(err);
            this.error = true;
          })
          .finally(() => {
            this.$store.commit("setLoading", false);
          });
      }
    },
  },
};
</script>

<style>
@import url(//fonts.googleapis.com/css?family=Lato:300:400);

body {
  margin: 0;
}
.border {
  background: #fff;
  width: 50%;
  height: 5px;
  border-radius: 5px;
}
h1 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
}

p {
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
}

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(60deg, #187da0 0%, #6f96aa 100%);
  color: white;
}

.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  h1 {
    font-size: 24px;
  }
}
</style>
